import { PaginatedResponse } from '@/lib/types';
import { getBearerToken } from '@/lib/utils/functions/authentication';
import jobreelApi from '@/lib/utils/jobreel-api';
import { Profile } from '@/modules/profile/profile.model';
import { UpdateUserDto } from '@/modules/user/user.dto';
import { User } from '@/modules/user/user.model';

export async function User_Update(payload: UpdateUserDto) {
  const { data } = await jobreelApi.patch<User>(`/v2/users`, payload, {
    headers: {
      Authorization: getBearerToken(),
    },
  });
  return data;
}

export async function User_ListForBusinessAddress(query: {
  addressHash: string;
  radiusMiles: number;
  skip: number;
  limit: number;
}) {
  const { data } = await jobreelApi.get<
    PaginatedResponse<User & { profiles: Profile[] }>
  >(`/v2/users/listForBusinessAddress`, {
    params: query,
    headers: {
      Authorization: getBearerToken(),
    },
  });
  return data;
}
