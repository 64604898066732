import { User_Current, User_Retrieve } from '@/chore/services/User.service';
import { User_ListForBusinessAddress } from '@/chore/services/v2/User.service';
import {
  getCurrentBusiness,
  tokenExists,
} from '@/lib/utils/functions/authentication';
import {
  keepPreviousData,
  useInfiniteQuery,
  useQuery,
} from '@tanstack/react-query';

export function useUser() {
  const query = useQuery({
    queryKey: [`user-current`],
    queryFn: User_Current,
    refetchInterval: 30 * 1000,
    enabled: tokenExists(),
  });

  return query;
}

export function useUserByHash(hash: string) {
  const query = useQuery({
    queryKey: [`user-${hash}`],
    queryFn: async () => User_Retrieve(hash),
    enabled: !!hash?.length,
  });
  return query;
}

export function useListUsersForBusienssAddress(query: {
  addressHash: string;
  radiusMiles: number;
  skip?: number;
  limit?: number;
}) {
  return useInfiniteQuery({
    queryKey: ['user-list-for-business', query.addressHash, query.radiusMiles],
    queryFn: async ({ pageParam }) => {
      return await User_ListForBusinessAddress({
        ...query,
        skip: (query.skip ?? query.limit ?? 20) * pageParam,
        limit: query.limit ?? 20,
      });
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (!lastPage.hasMore) {
        return undefined;
      }
      return lastPageParam + 1;
    },
    getPreviousPageParam: (firstPage, allPages, firstPageParam) => {
      if (firstPageParam <= 1) {
        return undefined;
      }
      return firstPageParam + 1;
    },
    refetchInterval: 5 * 60 * 1000,
    enabled: !!query.addressHash.length && !!getCurrentBusiness(),
  });
}

export function useListUsersForBusienssAddressPaginated(query: {
  addressHash: string;
  radiusMiles?: number;
  limit?: number;
  page?: number;
}) {
  return useQuery({
    queryKey: [
      'user-list-for-business',
      query.addressHash,
      query.radiusMiles,
      query.page ?? 1,
    ],
    retry: false,
    queryFn: async () =>
      await User_ListForBusinessAddress({
        addressHash: query.addressHash,
        radiusMiles: query.radiusMiles ?? 50,
        skip: (query.limit ?? 20) * ((query?.page ?? 1) - 1),
        limit: query.limit ?? 20,
      }),
    refetchInterval: 5 * 1000 * 60,
    enabled: !!query.addressHash.length,
  });
}
